import { createSlice } from '@reduxjs/toolkit';
import { FV, FV2, FVBREAKDOWN } from '@utils/futureValueUtils';

//FORMULAS
function PV(rate, num, amount, remain, type) {
    let factor;
    if (type == 0) {
        factor = (rate == 0 ? num : (1 - Math.pow(1 + rate, -num)) / rate);
    } else {
        factor = (rate == 0 ? num : (1 - Math.pow(1 + rate, -num)) * (1 + rate) / rate);
    }
    const pv = -amount * factor - remain * Math.pow(1 + rate, -num);
    return pv.toFixed(2)
};

const initialState = {
    simuladores: [],
    simulador: {
        titulo: "FONDO",
        daysInYear: 360,
        montoInversion: 0,
        rendimiento: 6.04,
        plazo: 370,
        interesGanado: 0,
        futureValue: 0,
        rendimientoMensual: [],
        rendimientoDiario: []
    },

    fondoOptions: [
        {
            codigo_servicio: "000038",
            titulo: "RentaPlus",
            slug: "rentaplus",
            rendimiento: 8.40,
            plazo: 370,
            picked: true,
            plazoicon: "permanencia_370_dias",
        },
        {
            codigo_servicio: "000040",
            titulo: "Superior",
            slug: "superior",
            rendimiento: 7.80,
            plazo: 180,
            picked: false,
            plazoicon: "permanencia_180_dias",

        },
        {
            codigo_servicio: "000035",
            titulo: "Eficaz",
            slug: "eficaz",
            rendimiento: 7.80,
            plazo: 180,
            picked: false,
            plazoicon: "permanencia_180_dias",
        },
        {
            codigo_servicio: "000033",
            titulo: "Estratégico",
            slug: "estrategico",
            rendimiento: 7.25,
            plazo: 180,
            picked: false,
            plazoicon: "permanencia_180_dias",
        },
        {
            codigo_servicio: "000029",
            titulo: "Master",
            slug: "master",
            rendimiento: 7.00,
            plazo: 90,
            picked: false,
            plazoicon: "permanencia_90_dias",
        }
    ],
    loading: false,
    toggle: false,
    inverso: false,
    step: 0,
}


const slice = createSlice({
    name: 'simuladorCortoPlazo',
    initialState,
    reducers: {

        CHANGE_STEP(state, action) {
            state.step = action.payload;
        },


        SET_FONDO(state, action) {

            //-- cambio de opcion
            const options = [...state.fondoOptions];
            const foundIndex = options.findIndex(item => item.slug === action.payload.slug);
            const current = { ...options[foundIndex], picked: true }
            options.forEach((el) => { el.picked = false; });
            options[foundIndex] = current;
            state.fondoOptions = options;
            //-- cambio de opcion

            state.simulador = {
                ...state.simulador,

                montoInversion: 0,
                interesGanado: 0,
                futureValue: 0,
                rendimientoMensual: [],
                rendimientoDiario: [],


                titulo: `Fondo ${current.titulo}`,
                rendimiento: current.rendimiento,
                plazo: current.plazo,
                codigo_servicio: current.codigo_servicio
            }

        },

        CHANGE_FIELD(state, action) {
            const rate = state.simulador.rendimiento / 100 / state.simulador.daysInYear;
            const nper = state.simulador.plazo;
            const pv = parseFloat(action.payload.montoInversion) || 0;
            console.log({ rate, nper, pv });
            //const futureValue = FV(rate, nper, null, -pv, null);
            const rendimiento = state.simulador.rendimiento;
            const futureValue = FV2(rendimiento, nper, pv);
            const interesGanado = (futureValue - pv).toFixed(2);
            const { rendimientoMensual, rendimientoDiario } = FVBREAKDOWN(rate, nper, null, -pv, null);
            state.simulador = {
                ...state.simulador,
                ...action.payload,
                interesGanado,
                futureValue,
                montoInversion: parseFloat(action.payload.montoInversion),
                rendimientoMensual,
                rendimientoDiario,
            };
        },


        SET_IS_INVERSO(state, action) {
            state.inverso = action.payload
        },

        CHANGE_FIELD_INVERSO(state, action) {

            const rate = state.simulador.rendimiento / 100 / state.simulador.daysInYear;
            const nper = state.simulador.plazo;
            const fv = parseFloat(action.payload.futureValue) || 0;

            const pv = parseFloat(PV(rate, nper, null, -fv, null));
            const { rendimientoMensual, rendimientoDiario } = FVBREAKDOWN(rate, nper, null, -pv, null);

            const interesGanado = (fv - pv).toFixed(2);

            state.simulador = {
                ...state.simulador,
                futureValue: parseFloat(action.payload.futureValue),
                montoInversion: pv,
                rendimientoMensual,
                rendimientoDiario,
                interesGanado
            }


        },






    }
});

export default slice.reducer;

export const calculateSimulacion = (payload, action) => async (dispatch) => {
    const actions = slice.actions;
    if (action === "SET_FONDO") {
        dispatch(actions.SET_FONDO(payload));
    }
    if (action === "CHANGE_FIELD") {
        dispatch(actions.CHANGE_FIELD(payload));
    }
    if (action === "CHANGE_FIELD_INVERSO") {
        dispatch(actions.CHANGE_FIELD_INVERSO(payload));
    }
}
export const changeStep = (step) => async (dispatch) => {
    dispatch(slice.actions.CHANGE_STEP(step))
}
export const setIsInverso = (isInverso) => async (dispatch) => {
    dispatch(slice.actions.SET_IS_INVERSO(isInverso))
}



