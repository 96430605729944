import { createSlice } from '@reduxjs/toolkit';
import { jsFileToBlob } from 'utils/fileUtils';
import cs_axs from '@axios/cs_axs';
import { message } from 'antd';
import { getDocumentos } from '@utils/api';

export const parseDocumentos = (documentos) => {
    if (documentos) {
        let parsed = documentos.map(item => {
            const { id, titulo, orden, url } = item;
            const parent = item.entradaHasDocuments &&
                item.entradaHasDocuments.length ?
                item.entradaHasDocuments[0].entrada?.slug : '_Sin_asignar';
            return {
                id,
                titulo,
                orden,
                url,
                parent
            }
        });

        parsed = _.orderBy(parsed, 'orden', 'asc')

        parsed = _.groupBy(parsed, 'parent');

        return parsed;


    } else {
        return {}
    }
}


const init = {
    documentos: [],
    documento: null,
    documentoUpdate: null,
    entradasHasDocuments: [],
    documentosByCat: {},
    loading: false,
    error: false,
    progress: 0,
};


const slice = createSlice({
    name: "documentos",
    initialState: init,
    reducers: {
        createDocumentoFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        createDocumentoSuccess(state, action) {
            state.loading = false;
            state.documentos.push(action.payload)
            state.documento = action.payload
        },
        createDocumentoProgress(state, action) {
            state.progress = action.payload;
        },
        createDocumentoError(state, action) {
            state.loading = false;
            state.error = action.payload
        },
        readDocumentosFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        readDocumentosSuccess(state, action) {
            state.loading = false;
            state.documentos = action.payload;
            state.documentosByCat = parseDocumentos(action.payload);
        },
        readDocumentosError(state, action) {
            state.loading = false;
            state.error = action.payload
        },

        updateDocumentosFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        updateDocumentosSuccess(state, action) {
            // debugger;
            state.loading = false;
            state.documentoUpdate = action.payload;

        },
        updateDocumentosError(state, action) {
            state.loading = false;
            state.error = action.payload
        },

        //CUSTOM::
        setCurrent(state, action) {
            state.documento = action.payload;
        },

        setEntradasHasDocuments(state, action) {
            state.entradasHasDocuments = action.payload;
        },

        setNewEntradasHasDocuments(state, action) {
            state.entradasHasDocuments.push(action.payload);
        },

        deleteEntradaDoumento(state, action) {
            state.entradasHasDocuments = state.entradasHasDocuments.filter(({ id }) => id !== action.payload);
        }
    }
});

export const createDocumento = ({ titulo, file }) => async (dispatch) => {
    dispatch(slice.actions.createDocumentoFetch());
    try {
        const blobFile = await jsFileToBlob(file);

        const formData = new FormData();

        formData.append("File", blobFile);
        formData.append("name", titulo);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                // (`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    // ('triger percent');
                    dispatch(slice.actions.createDocumentoProgress(percent));
                }
            }
        };
        cs_axs.post(`/cms/documentos`, formData, options).then(res => {
            if (res.data && res.data.id) {
                const documento = {
                    ...res.data,
                    url: `/documentos/${res.data.id}`
                }
                dispatch(slice.actions.createDocumentoSuccess(documento))
            }
        });
    } catch (error) {
        dispatch(slice.actions.createDocumentoError(error));
    }
};

export const findDocumentos = () => async (dispatch) => {
    dispatch(slice.actions.readDocumentosFetch());
    try {
        //const { data } = await cs_axs.get(`/cms/documentos`);
        const data = getDocumentos();
        //console.log({ data });
        dispatch(slice.actions.readDocumentosSuccess(data));
    } catch (error) {
        dispatch(slice.actions.readDocumentosError(error));
    }
}

export const updateDocumento = (id, datos) => async (dispatch) => {
    dispatch(slice.actions.updateDocumentosFetch());
    try {
        const { data } = await cs_axs.put(`/cms/documentos/${id}`, datos);
        // (data);

        if (data.ok) {
            dispatch(findDocumentos());
            dispatch(slice.actions.updateDocumentosSuccess(data.documento));
            message.success('Documento actualizado');
            return;
        }
        dispatch(slice.actions.updateDocumentosError(data.msg));
    } catch (error) {
        console.log(error)
        //dispatch(slice.actions.createDocumentoError(error));
    }
}


export const setCurrentDocumento = (documento) => async (dispatch) => {
    dispatch(slice.actions.setCurrent(documento));
}
// 
export const startCreateEntradaDoumento = ({ entradaId, documentoId }) => async (dispatch, callback) => {
    try {
        const { data } = await cs_axs.post(`/cms/crearEntradaDocumento`, {
            entradaId,
            documentoId
        });

        // (data);

        dispatch(slice.actions.setNewEntradasHasDocuments(data));
        callback && callback();

    } catch (error) {
        console.log(error);
    }
}

export const startGetEntradasHasDocuments = () => async (dispatch, callback) => {
    try {
        const { data } = await cs_axs.get(`/cms/getEntradasHasDocuments`);
        dispatch(slice.actions.setEntradasHasDocuments(data));
        // debugger;
        callback && callback();

    } catch (error) {
        console.log(error);
    }
}

export const startDeleteEntradaDoumento = ({ entradaId, documentoId }) => async (dispatch, callback) => {
    try {
        const { data } = await cs_axs.delete(`/cms/eliminarEntradaDocumento/${entradaId}/${documentoId}`);

        if (data.error) {
            throw new Error(data.error);
        }

        dispatch(slice.actions.deleteEntradaDoumento(data.id));
        callback && callback();
    } catch (error) {
        console.log(error);
    }
}


export default slice.reducer;
